import { render, staticRenderFns } from "./productinfo.vue?vue&type=template&id=04dc1d02&scoped=true&"
import script from "./productinfo.vue?vue&type=script&lang=js&"
export * from "./productinfo.vue?vue&type=script&lang=js&"
import style0 from "./productinfo.vue?vue&type=style&index=0&id=04dc1d02&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04dc1d02",
  null
  
)

export default component.exports