<template>
    <div>
        <HeaderNavigation :info="$store.state.infos" :checkValue="12" />
        <div class="top_pic"><img :src="banner" alt=""></div>
        <div class="type-box">
            <div style="width:30%;margin-top: 90px;">
                <div  v-for="(t, idx) in types" :key="idx" style="width:90%" @click="productItemClick(t)">
                    <div class="type-one-box">
                        <img :src="t.image"  style="width:56px;height:56px;margin-right:40px;"/>
                        <div style="flex:1">{{ t.type_name }}</div>
                        <img src="../../assets/images/arrow-right.png"  style="width:20px;height:20px;"/>
                    </div>
                    <img src="../../assets/images/shadow.png"  style="width:100%;"/>
                </div>
            </div>
            <div class="content_box">
                <div class="content_title">{{Journalism.product_name}}</div> 
                <SwiperBox class="animate__animated animate__bounceInUp" :imgList="Journalism.more_img"></SwiperBox>
                <div style="margin-top:20px">
                    <h1>产品介绍</h1>
                </div>
                <div class="content_text" v-html="Journalism.new_content">
                </div>
            </div>
        </div>
        
        <BottomNavigation :info="$store.state.infos" />
        <RightMenu :info="$store.state.infos" @openpop="openpop" />
        <JoinBulletFrame v-if="JoinModal" @closepop="closepop" />
    </div>
</template>

<script>
    import {new_product,cateBanner,type_list, product_list} from '@/api/index.js'
    import HeaderNavigation from "@/components/header_navigation.vue";
    import BottomNavigation from "@/components/bottom_navigation.vue";
    import RightMenu from "@/components/right-menu.vue";
    import JoinBulletFrame from "@/components/joinBulletFrame.vue";
    import SwiperBox from "@/components/swiper_product.vue";
    export default {
        components: {
            HeaderNavigation,
            BottomNavigation,
            RightMenu,
            JoinBulletFrame,
            SwiperBox
        },
        data(){
            return{
                JoinModal:false,
                Journalism:{
                    more_img:[]
                },
                banner:'',
                types:[],
                product:[]
                
            }
        },
        created() {
            this.cateBanner()
            this.getInfo(this.$route.query.id)
            this.type_list();
            // this.Journalism = JSON.parse(localStorage.getItem('Journalism'))
        },
        methods:{
            productItemClick(e){
                this.$router.push({path:"/Productdetail",query:{id:e.id}})
            },
            type_list(){
                type_list().then(res=>{
                    this.types = res.data
                })
            },
            cateBanner(){
                cateBanner({id:12}).then(res=>{
                    this.banner = res.data
                })
            },
            getInfo(id){
                window.scroll(0, 0)
                new_product({id:id}).then(res=>{
                    this.Journalism = res.data.data
                })
            },
            openpop(e){
                this.JoinModal = e
            },
            closepop(e){
                this.JoinModal = e
            },
        }
    }
</script>

<style scoped>
.top_pic{
    width: 100%;
    height: 600px;
}
.top_pic img{
    width: 100%;
    height: 100%;
  object-fit: fill;
}
.content_box{
    width: 70%;
    margin: 30px auto 50px;
}
.content_title{
    color: #000;
    font-size: 28px;
    text-align: center;
}
.content_time{
    font-size: 16px;
    color: #999;
    text-align: center;
    margin: 20px 0;
}
.content_text{
    margin: 20px 0 40px 0;
    font-size: 16px;
    color: #999;
}
.bottom_btn{
    width: 80%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0;
}
.pagebtn{
    /* background: #313131; */
    border: 1px solid #313131;
    color: #313131;
    padding: 10px 30px;
    border-radius: 9px;
    cursor: pointer;
}
>>>img{
    max-width: 100%;
}
.type-box{
    display: flex;width:80%;margin:0  auto  50px
}
.type-one-box{
    display: flex;align-items: center; width:100%;padding:0 30px;margin:10px 0;cursor:pointer
}
</style>