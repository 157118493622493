<template>
  <div class="home">
    <div style="display:flex;margin-top:20px"  v-if="imgList.length>0">
        <div class="two-swiper-box">
          <swiper :options="swiperOption_two" class="two_swiper"   ref="myTwoSwiper">
            <swiper-slide v-for="(item,index) of imgList" :key="index"  class="swiper-slide">
              <img :src="item" alt="" @click="changeImg(index)"  @focus="changeImg(index)" style="cursor: pointer;" >
            </swiper-slide>
            
            <div class="swiper-button-prev" slot="button-prev" v-if="vlast_index > 0"  @click="vprev()">
              <svg t="1722135965187" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2323" width="100%" height="100%">
                <path d="M308.352 609.621333a32 32 0 0 1 0-45.269333l181.034667-181.034667a32 32 0 0 1 45.226666 0l181.034667 181.034667a32 32 0 0 1-45.226667 45.226667L512 451.242667l-158.378667 158.421333a32 32 0 0 1-45.269333 0z" fill="#000000" p-id="2325"></path>
              </svg>
                
              </div>
                <!--左箭头。如果放置在swiper外面，需要自定义样式。-->
              <div class="swiper-button-next" slot="button-next"   v-if="vlast_index < (imgList.length - 4) && imgList.length > 4" @click="vnext()">
                <svg t="1722136013390" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2484" width="100%" height="100%">
                  <path d="M715.648 414.378667a32 32 0 0 1 0 45.269333l-181.034667 181.034667a32 32 0 0 1-45.226666 0l-181.034667-181.034667a32 32 0 1 1 45.226667-45.226667L512 572.757333l158.378667-158.421333a32 32 0 0 1 45.269333 0z" fill="#000000" p-id="2486"></path></svg>
              </div>
          </swiper>
        </div>
        <div class="swiper-box" >
          <swiper :options="swiperOption" class="swiper"  ref="mySwiper">
            <swiper-slide v-for="(item,index) of imgList" :key="index" class="swiper-slide">
              <img :src="item" alt="">
            </swiper-slide> 
          </swiper>
            <div class="swiper-button-prev" v-if="last_index> 0" slot="button-prev"  @click="prev()">
              <svg t="1722132759207" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2162" width="100%" height="100%">
                <path d="M564.352 318.72a32 32 0 0 1 45.226667 45.226667l-158.378667 158.421333 158.421333 158.378667a32 32 0 1 1-45.269333 45.226666l-181.034667-180.992a32 32 0 0 1 0-45.226666l181.034667-181.034667z" fill="#000000" p-id="2163"></path>
                </svg>
              
            </div>
              <!--左箭头。如果放置在swiper外面，需要自定义样式。-->
            <div class="swiper-button-next" v-if="last_index < (imgList.length - 1)" slot="button-next"  @click="next()">
              <svg  t="1722132554904" class=" icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2001" width="100%" height="100%">
              <path d="M414.378667 318.72a32 32 0 0 1 45.269333 0l181.034667 180.992a32 32 0 0 1 0 45.269333l-181.034667 181.034667a32 32 0 0 1-45.226667-45.269333l158.378667-158.378667L414.378667 363.946667a32 32 0 0 1 0-45.226667z" fill="#000000" p-id="2003"></path>
              </svg>
          
            </div>
        </div>
        
    </div>
    
    
  </div>
</template>

<script>
// @ is an alias to /src
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  name: "product",
  components:{swiper,swiperSlide},
  props: {
    imgList:Array
  },
  data() {
    return {
      swiperOption:{
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      swiperOption_two: {
        spaceBetween: 10,
        slidesPerView: 4,
        direction: 'vertical',
        freeMode: true,
        watchSlidesProgress: true,
        navigation: {
          nextEl: '.ver-swiper-button-next',
          prevEl: '.ver-swiper-button-prev',
        },
      },
      last_index:0,
      vlast_index:0
    };
  },
  mounted() {
  },
  methods:{
    
    prev(){
      this.$refs.mySwiper.swiper.slidePrev();
      this.last_index =  this.$refs.mySwiper.swiper.activeIndex
      
    },
    next(){
      this.$refs.mySwiper.swiper.slideNext();
      this.last_index =  this.$refs.mySwiper.swiper.activeIndex
    },
    vprev(){
      this.$refs.myTwoSwiper.swiper.slidePrev();
      this.vlast_index =  this.$refs.myTwoSwiper.swiper.activeIndex
      
    },
    vnext(){
      this.$refs.myTwoSwiper.swiper.slideNext();
      this.vlast_index =  this.$refs.myTwoSwiper.swiper.activeIndex
      console.log(this.vlast_index)
    },
    changeImg(v){
      this.$refs.mySwiper.swiper.slideTo(v);
    }
  }
};
</script>

<style scoped>
 .swiper{
    height: 400px;
    width: 800px;
}
 img{
  object-fit: fill!important;
}
.swiper .swiper-slide {
  width: 100%;
  height: 100%;
  /* background-color: #42b983;
  text-align: center; */
  /* line-height: 500px; */
}
.swiper .swiper-slide img{
    width: 100%;
    height: 100%;
}
.swiper .swiper-slide {
  width: 100%;
  height: 100%;
}
.two_swiper{
  height:400px;
  width:180px;
}
.two_swiper .swiper-slide{
    width: 100%;
    height: 30%;
}
.two_swiper .swiper-slide img{
    width: 100%;
    height: 100%;
}
.swiper-container{
  margin:0 10px
}
  .swiper-box .swiper-button-prev, .swiper-box .swiper-button-next{
    height:48px;
    width:48px;
    background-color: #fff;
    border-radius: 50%;
    border: 2px solid #fff;
    background-image: unset;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .swiper-button-prev:hover, .swiper-button-next:hover{
    border-color:#222 !important;
  }
  .swiper-box{
    width: 730px;
    display: flex;
    justify-content: center;
    position: relative;
  }
  .two-swiper-box  .swiper-button-prev{
    top:30px;
  }
  .two-swiper-box  .swiper-button-next{
    top:unset;
    bottom:10px;
  }
  .two-swiper-box  .swiper-button-prev, .two-swiper-box  .swiper-button-next{
    left:40%;
    height:28px;
    width:28px;
    background-color: #fff;
    border-radius: 50%;
    border: 2px solid #fff;
    background-image: unset;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .two_swiper img{
    border-radius: 5px;
  }
  .two_swiper img:hover{
    border:1px solid #222
  }
</style>
